<template>
  <header class="p-3 mb-3 border-bottom">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span class="fs-4">Bodadata's Monitors</span>
      </a>
      <div class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"></div>
      <ul class="nav justify-content-center">
        <li>
          <a class="nav-link" href="https://grafana.staff.pbsbd.cn:10443">Grafana</a>
        </li>
      </ul>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import "bootstrap/dist/css/bootstrap.min.css"

export default {
  name: "header-navbar",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    return {}
  }
}
</script>

<style scoped>
header {
  background-color: #fff;
}
.nav-link {
  color: #212529;
}
</style>