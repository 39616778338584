
import "bootstrap/dist/css/bootstrap.min.css"

export default {
  name: "header-navbar",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    return {}
  }
}
