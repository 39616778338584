<template>
  <div>
    <header-navbar/>
    <main>
      <router-view v-slot="{ Component }">
        <transition name="router-fade" mode="out-in">
          <keep-alive>
            <component :is="Component"/>
          </keep-alive>
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script lang="ts">
import HeaderNavbar from './components/fixed/header-navbar.vue'

export default {
  components: {
    HeaderNavbar
  }
}
</script>

<style>
@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-Bold-Italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-Medium-Italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'),
  url('./fonts/woff/JetBrainsMono-ExtraBold-Italic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

#app {
  font-family: "JetBrains Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.space {
  margin: 0;
  padding: 0;
  height: 50px;
}

body {
  font-size: .9rem;
  background-color: #f5f8fa;
}
</style>
