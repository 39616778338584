<template>
  <div class="flex-center position-ref full-height">
    <div class="fullscreen-bg">
    </div>

    <div class="warp">
      <h1>Bodadata's Monitors</h1>
      <a href="https://grafana.staff.pbsbd.cn:10443">Grafana</a>
      <h4>
        <!--
        <a v-show="gabano" target="_blank" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + gabano">
          <img v-show="gabano" src="https://oss.bj.iel.pub/website/images/gongan.png"/>京公网安备 {{ gabano }}号</a>
        -->
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=京公网安备11010502053708号">
          <img src="https://img.storage.staff.pbsbd.cn/static/gongan.png">京公网安备11010502053708号
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn">粤ICP备19020774号-1</a>
      </h4>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Home',
  setup() {
    return {}
  }
});
</script>

<style>
.fullscreen-bg {
  position: fixed;
  background-color: #000000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

video {
  width: 100%;
}

.warp::after {
  color: #eeeeee;
  font-family: 'JetBrains Mono', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

.warp {
  background-color: rgba(255,255,255,0.7);
  margin: 30px auto;
  width: 1200px;
  text-align: center;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
  /*box-shadow: 0 0 12px 3px rgba(0,0,0,0.18);*/
}

.warp h1 {
  font-size: 84px;
  color:#666666;
  text-shadow:0 0 10px rgba(255,255,255,0.2);
}

.warp h4 {
  text-align: center;
  font-size: 12px;
  font-family: "JetBrains Mono","Microsoft Yahei",fangsong;
}

.warp a {
  color: #666666;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.wrap::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 60px;
  z-index: -1;
  filter: blur(20px);
  margin: -30px
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}
</style>